// src/store/slice.js
import { createSlice } from '@reduxjs/toolkit';

const dataSlice = createSlice({
  name: 'data',
  initialState: { data: [] },
  reducers: {
    fetchDataSuccess(state, action) {
      state.data = action.payload;
    },
  },
});

export const { fetchDataSuccess } = dataSlice.actions;
export default dataSlice.reducer;
