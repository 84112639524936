import React, { useState } from 'react';
import Editor from '@monaco-editor/react'; // Importing Monaco Editor
import ReactJson from 'react-json-view';
import { json as generateJsonSchema } from 'generate-schema'; // Schema generator

const JsonToSchemaConverter = () => {
  const [jsonData, setJsonData] = useState(''); // State to hold JSON input
  const [jsonSchema, setJsonSchema] = useState(''); // State to hold generated schema
  const [error, setError] = useState(null); // State for error messages
  const [showRaw, setShowRaw] = useState(true); // Toggle between raw and hierarchical views

  // Handle file upload for JSON
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const parsedData = JSON.parse(event.target.result); // Parse JSON data from file
        setJsonData(JSON.stringify(parsedData, null, 2)); // Store prettified JSON data
        generateSchemaFromData(parsedData); // Generate schema from JSON data
      } catch (error) {
        setError('Invalid JSON file. Please upload a valid JSON file.');
      }
    };
    if (file) reader.readAsText(file); // Read file content
  };

  // Handle generating the schema based on current JSON data
  const handleGenerateSchema = () => {
    try {
      const parsedData = JSON.parse(jsonData); // Parse the current JSON data from the editor
      generateSchemaFromData(parsedData); // Generate the schema from parsed JSON data
      setError(null); // Reset errors
    } catch (error) {
      setError('Invalid JSON input. Please correct the JSON and try again.');
    }
  };

  // Generate JSON schema from JSON data
  const generateSchemaFromData = (data) => {
    try {
      const schema = generateJsonSchema('GeneratedSchema', data); // Generate JSON schema
      const prettifiedSchema = JSON.stringify(schema, null, 2); // Prettify the generated schema
      setJsonSchema(prettifiedSchema); // Store prettified schema
    } catch (error) {
      setError('Error generating schema. Please ensure valid JSON input.');
    }
  };

  // Function to copy JSON schema to clipboard
  const copyToClipboard = () => {
    navigator.clipboard.writeText(jsonSchema).then(
      () => alert('Schema copied to clipboard!'),
      (err) => alert('Failed to copy: ', err)
    );
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4 text-center">JSON to JSON Schema Converter</h1>

      {/* Upload Button */}
      <div className="mb-4 flex justify-center">
        <input 
          type="file" 
          accept=".json"
          onChange={handleFileUpload}
          className="file-input file-input-bordered file-input-primary"
        />
      </div>

      <div className="grid grid-cols-2 gap-4 h-full">
        {/* JSON Input Area */}
        <div className="flex flex-col border rounded-lg p-4 bg-gray-100 shadow-lg h-full">
          <h2 className="text-xl font-semibold mb-2">JSON Input</h2>

          {/* Monaco Editor for JSON Input with fixed height */}
          <Editor
            height="500px" // Fixed height for the input editor
            defaultLanguage="json" // JSON language support
            value={jsonData} // Controlled component
            onChange={(code) => setJsonData(code || '')} // Handle changes
            options={{
              minimap: { enabled: false }, // Disable minimap for clean UI
              formatOnType: true, // Auto format
              scrollBeyondLastLine: false, // Don't scroll beyond last line
            }}
          />

          {/* Error Message */}
          {error && <p className="text-red-500 mt-2">{error}</p>}

          {/* Generate JSON Schema Button */}
          <button onClick={handleGenerateSchema} className="btn btn-primary mt-4">
            Generate JSON Schema
          </button>
        </div>

        {/* JSON Schema Output Area */}
        <div className="flex flex-col border rounded-lg p-4 bg-gray-100 shadow-lg h-full">
          <div className="flex justify-between mb-2">
            <h2 className="text-xl font-semibold">Generated JSON Schema</h2>

            {/* Toggle Button to switch between Raw and Hierarchical View */}
            <button
              className="btn btn-outline"
              onClick={() => setShowRaw(!showRaw)}
            >
              {showRaw ? 'Show Hierarchical View' : 'Show Raw JSON'}
            </button>
          </div>

          {/* Scrollable content with max height */}
          <div className="overflow-y-auto h-full">
            {jsonSchema ? (
              showRaw ? (
                <>
                  {/* Monaco Editor for Prettified Raw JSON Schema View with fixed height */}
                  <Editor
                    height="500px" // Fixed height for the output editor
                    defaultLanguage="json" // JSON language support
                    value={jsonSchema} // Controlled component
                    options={{
                      readOnly: true, // Read-only for output
                      minimap: { enabled: false }, // Disable minimap
                      scrollBeyondLastLine: false, // No extra scroll
                    }}
                  />

                  {/* Copy to Clipboard Button */}
                  <button
                    className="btn btn-outline mt-4"
                    onClick={copyToClipboard}
                  >
                    Copy to Clipboard
                  </button>
                </>
              ) : (
                <ReactJson src={JSON.parse(jsonSchema)} theme="monokai" collapsed={true} enableClipboard={true} />
              )
            ) : (
              <p className="text-gray-500">The JSON Schema will be displayed here once JSON is entered.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JsonToSchemaConverter;
