// src/App.js
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

function Home() {
//   const dispatch = useDispatch();
//   const data = useSelector(state => state.data.data);

//   useEffect(() => {
//     dispatch({ type: 'data/fetchData' });
//   }, [dispatch]);

  return (<h1>Hello</h1>
  );
}

export default Home;
