import React from 'react';
import SearchBar from '../components/SearchBar';
import ItemList from '../components/ItemList';

const ToolsPage = () => {
    return (
        <div className="relative">
            {/* Search Bar */}
            <SearchBar />

            {/* List of Items */}
            <ItemList />
        </div>
    );
};

export default ToolsPage;
