import React from 'react';
import Item from './Item'; // Import the Item component
import { useSelector } from 'react-redux';

const ItemList = () => {
    const { filteredItems } = useSelector((state) => state.search);

    return (
        <main className="mt-32 p-6">
            {filteredItems.length > 0 ? (
                <ul className="space-y-4">
                    {filteredItems.map((item) => (
                        <Item key={item.id} item={item} />
                    ))}
                </ul>
            ) : (
                <p className="text-center text-gray-500">No results found</p>
            )}
        </main>
    );
};

export default ItemList;
