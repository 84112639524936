import { createSlice } from '@reduxjs/toolkit';
import Fuse from 'fuse.js';

// Sample list of items
const items = [
  { id: 1, title: 'Diff Viewer', description: 'Diff Viewer allows you to find out differences in two text files/content', url: "/tools/diffViewer" },
  { id: 2, title: 'Json to Schema', description: 'Create json schema from a json input file/data', url: "/tools/jsontoschema" },
  // { id: 3, title: 'Item Three', description: 'The third item with some text', url: "/tools/test3" },
  // Add more items as needed
];

const fuse = new Fuse(items, { keys: ['description'], threshold: 0.3 });

const searchSlice = createSlice({
  name: 'search',
  initialState: {
    query: '',
    filteredItems: items, // Initially, all items are shown
  },
  reducers: {
    setSearchQuery: (state, action) => {
        const query = action.payload;
        state.query = query;
  
        if (query === '') {
          // If the search query is empty, return all items
          state.filteredItems = items;
        } else {
          // Perform fuzzy search if there is a query
          state.filteredItems = fuse.search(query).map(result => result.item);
        }
    },
  },
});

export const { setSearchQuery } = searchSlice.actions;
export default searchSlice.reducer;
