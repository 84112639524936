import React from 'react';
import { Link } from 'react-router-dom';

const Item = ({ item }) => {
    return (
        <li className="p-4 border rounded-lg shadow hover:bg-gray-100 transition">
            <Link to={item.url} className="block w-full h-full">
                <h2 className="text-xl font-bold">{item.title}</h2>
                <p className="text-gray-600">{item.description}</p>
            </Link>
        </li>
    );
};

export default Item;
