// src/store/rootReducer.js
import { combineReducers } from 'redux';
import dataReducer from './slice';
import searchReducer from './searchSlice';

const rootReducer = combineReducers({
  data: dataReducer,
  search: searchReducer,
});

export default rootReducer;
