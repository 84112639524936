import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchQuery } from '../store/searchSlice';

const SearchBar = () => {
    const dispatch = useDispatch();
    const { query } = useSelector((state) => state.search);

    const handleSearchChange = (e) => {
        dispatch(setSearchQuery(e.target.value));
    };

    return (
        <div className="fixed top-16 w-full z-10 flex justify-center p-4">
            <input
                type="text"
                value={query}
                onChange={handleSearchChange}
                placeholder="Search by description..."
                className="input input-bordered w-1/2 rounded-full shadow-md"
            />
        </div>
    );
};

export default SearchBar;
