// src/App.js
import React, { useEffect, Suspense, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ToolsPage from './pages/toolsPage';
import Header from './Header';
import Home from './pages/Home';
// import { Routes, Route, Link } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

const loadToolPages = () => {
  const context = require.context('./pages/tools', true, /\.js$/);
  const routes = context.keys().map((key) => {
    const path = key.replace('./', '').replace('.js', '');
    const module = context(key);

    // console.log(module); // Add this to debug the imported module

    return {
      name: path,
      // Ensure the module is resolved correctly by checking .default (common for ES module exports)
      Component: React.lazy(() => Promise.resolve({ default: module.default || module })),
    };
  });
  return routes;
};

function App() {
  // const dispatch = useDispatch();
  // const data = useSelector(state => state.data.data);

  // useEffect(() => {
  //   dispatch({ type: 'data/fetchData' });
  // }, [dispatch]);

  const [toolRoutes, setToolRoutes] = useState([]);

  useEffect(() => {
    const routes = loadToolPages();
    setToolRoutes(routes);
  }, []);

  return (<>
    <Header />

    {/* Main content, offset to account for fixed header */}
    <main className="p-2" >
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tools" element={<ToolsPage />} />
          {toolRoutes.map(({ name, Component }) => (
            <Route key={name} path={`/tools/${name}`} element={<Component />} />
          ))}
        </Routes>
      </Suspense>
    </main>
  </>
  );
}

export default App;
