// src/store/rootSaga.js
import { takeEvery, call, put } from 'redux-saga/effects';
import { fetchDataSuccess } from './slice';

// API function to fetch data
function fetchDataApi() {
  return fetch('https://jsonplaceholder.typicode.com/posts').then(response => response.json());
}

// Worker saga: performs the async task
function* fetchDataSaga() {
  try {
    const data = yield call(fetchDataApi);
    yield put(fetchDataSuccess(data));
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

// Watcher saga: watches for actions dispatched to the store
export default function* rootSaga() {
  yield takeEvery('data/fetchData', fetchDataSaga);
}
