import React, { useState, useEffect, useCallback } from 'react';
import ReactDiffViewer from 'react-diff-viewer';
import { debounce } from 'lodash';

const DiffViewer = () => {
  const [code1, setCode1] = useState('');
  const [code2, setCode2] = useState('');
  const [debouncedCode1, setDebouncedCode1] = useState('');
  const [debouncedCode2, setDebouncedCode2] = useState('');

  // Function to handle file uploads
  const handleFileUpload = (e, setCode) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => setCode(event.target.result);
    if (file) reader.readAsText(file);
  };

  // Debounced function to update the diff viewer inputs
  const updateDiff = useCallback(
    debounce((newCode1, newCode2) => {
      setDebouncedCode1(newCode1);
      setDebouncedCode2(newCode2);
    }, 500), // 500ms delay
    []
  );

  // Update debounced code when code inputs change
  useEffect(() => {
    updateDiff(code1, code2);
  }, [code1, code2, updateDiff]);

  // Calculate percentage match or differences
  const calculateDiffStats = (code1, code2) => {
    if (!code1 || !code2) return { matchPercent: 0, diffPercent: 0 };
    
    const lines1 = code1.split('\n');
    const lines2 = code2.split('\n');
    const totalLines = Math.max(lines1.length, lines2.length);

    // Calculate differences line by line
    let diffLines = 0;
    for (let i = 0; i < totalLines; i++) {
      if (lines1[i] !== lines2[i]) {
        diffLines++;
      }
    }

    const matchPercent = ((totalLines - diffLines) / totalLines) * 100;
    const diffPercent = (diffLines / totalLines) * 100;

    return { matchPercent, diffPercent };
  };

  const stats = calculateDiffStats(code1, code2);

  return (
    <div className="p-4">
      <h1 className="text-3xl font-bold mb-4">Beautiful Diff Viewer</h1>
      <div className="grid grid-cols-2 gap-4 mb-4">
        {/* Code Input 1 */}
        <div className="flex flex-col">
          <textarea
            className="textarea textarea-bordered resize-y mb-2 h-60"
            value={code1}
            onChange={(e) => setCode1(e.target.value)}
            placeholder="Paste or type code here..."
          />
          <input
            type="file"
            className="file-input file-input-bordered file-input-primary"
            accept=".txt, .js, .css, .html"
            onChange={(e) => handleFileUpload(e, setCode1)}
          />
        </div>

        {/* Code Input 2 */}
        <div className="flex flex-col">
          <textarea
            className="textarea textarea-bordered resize-y mb-2 h-60"
            value={code2}
            onChange={(e) => setCode2(e.target.value)}
            placeholder="Paste or type code here..."
          />
          <input
            type="file"
            className="file-input file-input-bordered file-input-primary"
            accept=".txt, .js, .css, .html"
            onChange={(e) => handleFileUpload(e, setCode2)}
          />
        </div>
      </div>

      {/* Stats */}
      <div className="stats shadow flex justify-center items-center">
        <div className="stat">
          <div className="stat-title">Total Lines</div>
          <div className="stat-value">{Math.max(code1.split('\n').length, code2.split('\n').length)}</div>
        </div>
        <div className="stat">
          <div className="stat-title">Match Percentage</div>
          <div className="stat-value text-green-500">{stats.matchPercent.toFixed(2)}%</div>
        </div>
        <div className="stat">
          <div className="stat-title">Difference Percentage</div>
          <div className="stat-value text-red-500">{stats.diffPercent.toFixed(2)}%</div>
        </div>
      </div>

      {/* Debounced Diff Viewer */}
      <div className="mb-4">
        <ReactDiffViewer oldValue={debouncedCode1} newValue={debouncedCode2} splitView={true} />
      </div>
    </div>
  );
};

export default DiffViewer;
